import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { useHostBinding } from '@pwiz/infra/ui';

@Component({
  selector: 'pw-drawer-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pw-drawer-footer.component.html',
  styleUrl: './pw-drawer-footer.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PwDrawerFooterComponent {
  align = input<'start' | 'end'>('end');

  constructor() {
    const { conditionalClass } = useHostBinding();
    effect(() => {
      conditionalClass(`align-start`, this.align() === 'start');
      conditionalClass(`align-end`, this.align() === 'end');
    });
  }
}
